
import { Component, Mixins } from 'vue-property-decorator';
import { orgAdminStore } from '@/store';
import { BAIconsMixin, VuetifyMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import { OrganizationModel } from '@/models/organization/OrganizationModel';

@Component({
	components: {
		Page
	}
})
export default class OrgAdminDashboard extends Mixins(VuetifyMixin, BAIconsMixin){
	created(): void{
		orgAdminStore.loadOrganizations();
	}

	search = '';

	headers = [
		{ text: 'Organization', value: 'name', sortable: true, align: 'start' },
		{ text: 'Location', value: 'Location' },
		{ text: 'Type', value: 'type' },
		{ text: 'Teams', value: 'TeamCount', align: 'center' },
		{ text: '', value: 'actions' }
	]

	get Loading(): boolean{
		return !orgAdminStore.organizationsInitialized || orgAdminStore.organizationsLoading;
	}
	get OrgsReady(): boolean{
		return orgAdminStore.organizationsInitialized;
	}
	get Organizations(): OrganizationModel[]{
		return orgAdminStore.organizations;
	}

	async onViewOrg(organizationId: string) {
		this.$router.push({ name: OrgAdminRoutes.OrgAdminManageTeams,
			params:{
				organizationId,
			}
		});
	}
	async gotoOrgSettings(organizationId: string) {
		this.$router.push({ name: OrgAdminRoutes.OrgAdminSettings,
			params:{
				organizationId,
			}
		});			
	}
}
